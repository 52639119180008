<template>
  <div class="manage-project-refunds-list">
    <vue-good-table
      mode="remote"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      @on-row-click="toRefundDetail"
      :columns="refundsTableColumns"
      :rows="refundsTableData"
      :totalRows="refundsTablePagination.total"
      :isLoading="isLoading"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      :sort-options="{
        enabled: false
      }"
    >
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <div slot="emptystate">
        <no-data message="There is no pending refunds"></no-data>
      </div>
      <!-- Table Buttons -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'" @click.stop>
          <dropdown-button buttonClass="circle flat">
            <template #buttonContent>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <div class="card text-left">
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="openApproveModal(props.row.projectPurchase.id)"
              >
                <i class="fas fa-check mr-1"></i>
                Approve
              </fd-list-item>
              <fd-list-item
                class="cursor-pointer unselectable"
                @click="openRejectModal(props.row.projectPurchase.id)"
              >
                <i class="fas fa-times mr-1"></i>
                Reject
              </fd-list-item>
            </div>
          </dropdown-button>
        </span>
        <refund-status
          v-if="props.column.field == 'refundStatus'"
          :status="props.row.status"
        ></refund-status>
        <div v-if="props.column.field == 'plot'" class="plot">
          {{ props.row.projectPurchase.projectUnit.plot }}
        </div>
        <!-- ======================== AGENT INFO ======================= -->
        <span
          v-else-if="
            props.column.field == 'assignedAgent' &&
            !_.isEmpty(props.row.projectPurchase.agent)
          "
        >
          <p>{{ props.row.projectPurchase.agent.name }}</p>
          <span class="d-flex align-items-center">
            <i class="fas fa-phone mr-1"></i
            >{{ $getUserPhoneNumber(props.row.projectPurchase.agent.user) }}
          </span>
          <span class="d-flex align-items-center">
            <i class="fas fa-envelope mr-1"></i
            >{{ props.row.projectPurchase.agent.user.email }}
          </span>
        </span>
        <!-- ============================================================ -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- ========================= MODALS: APPROVE ========================= -->
    <modal v-model="approveModal.isShown">
      <approve-refund
        :refundId="approveModal.id"
        @cancel="approveModal.isShown = false"
        @submit="approveRefund"
      ></approve-refund>
    </modal>
    <!-- ========================= MODALS: REJECT ========================== -->
    <modal v-model="rejectModal.isShown">
      <reject-refund
        :refundId="rejectModal.id"
        @cancel="rejectModal.isShown = false"
        @submit="rejectRefund"
      ></reject-refund>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { projectRefundStatus as STATUS } from "@/enums";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner"),
    RefundStatus: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/RefundStatus"
      ),
    DropdownButton: () =>
      import("@/components/GlobalComponents/DropdownButton"),
    FdListItem: () => import("@/components/GlobalComponents/List/FdListItem"),
    // Modals
    ApproveRefund: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/ApproveRefund"
      ),
    RejectRefund: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/Refund/RejectRefund"
      )
  },
  mixins: [vueGoodTable],
  props: {
    isHistory: {
      type: Boolean,
      default: false
    },
    historyDetailRouteName: {
      type: String,
      default: "ProjectRefundHistoryDetails"
    },
    detailRouteName: {
      type: String,
      default: "ProjectRefundDetails"
    },
    getRefundsAPI: {
      type: Function,
      required: true
    },
    approveRefundAPI: {
      type: Function,
      required: true
    },
    rejectRefundAPI: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      refundsTableData: [],
      refundsTablePagination: {},
      refundsTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          hidden: this.isHistory
        },
        {
          label: "Status",
          field: "refundStatus"
        },
        {
          label: "Plot",
          field: "plot"
        },
        {
          label: "Project",
          field: "projectPurchase.project.name"
        },
        {
          label: "Buyer Name",
          field: "projectPurchase.projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer Acc",
          field: "projectPurchase.appUser.name"
        },
        {
          label: "Deposit",
          field: "refundDeposit",
          formatFn: (deposit) => `RM ${deposit}`
        },
        {
          label: "Agent",
          field: "assignedAgent"
        },
        {
          label: "Refund Submit Date",
          field: "createdAt",
          formatFn: (date) => this.$moment(date).format("DD-MM-YYYY")
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "name[partial]": ""
      },
      approveModal: {
        isShown: false,
        id: ""
      },
      rejectModal: {
        isShown: false,
        id: ""
      },

      isLoading: false
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllRefunds();
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAllRefunds();
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);
      this.updateParams({ perPage: params.currentPerPage });
      this.getAllRefunds().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toRefundDetail(param) {
      let id = param.row.projectPurchase.id;

      let routeName = this.isHistory
        ? this.historyDetailRouteName
        : this.detailRouteName;
      this.$router.push({
        name: routeName,
        params: { id: id }
      });
    },
    async openApproveModal(id) {
      this.approveModal.id = id;
      this.approveModal.isShown = true;
    },
    async openRejectModal(id) {
      this.rejectModal.id = id;
      this.rejectModal.isShown = true;
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());
      this.getAllRefunds();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllRefunds();
    },
    // ========================== API Related =========================
    async getAllRefunds() {
      let status = this.isHistory
        ? `${STATUS.ACCEPTED}|${STATUS.REJECTED}`
        : `${STATUS.PENDING_REQUEST}|${STATUS.PENDING_CONFIRM}`;
      try {
        this.isLoading = true;
        let data = await this.getRefundsAPI({
          queries: {
            "status[in]": status
          },
          page: this.tableParams.page,
          perPage: this.tableParams.perPage
        });

        this.refundsTableData = this._.cloneDeep(data.data);
        this.refundsTablePagination = this._.cloneDeep(data.meta.pagination);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get refunds list. Please try again later."
        });
        throw error;
      }
    },
    async approveRefund(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.approveRefundAPI(id, payload);
        this.approveModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "The refund has been approved and completed successfully."
        });
        await this.getAllRefunds();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to approve refund. Please try again later."
        });
      }
    },
    async rejectRefund(id, payload) {
      try {
        this.$store.commit("setIsLoading", true);
        await this.rejectRefundAPI(id, payload);
        this.rejectModal.isShown = false;
        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "The refund has been rejected successfully."
        });
        await this.getAllRefunds();
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to reject refund. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss">
.manage-project-refunds-list {
  .plot {
    background: #eee;
    color: #555;
    font-weight: bold;
    text-align: center;
    padding: 8px;
    border-radius: 5px;
  }
}
</style>
